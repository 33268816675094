/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import { getRandomNumber } from "../utils";

const CONFIGURATIONS = [
  {
    width: 480,
    height: 320,
    viewBox: [0,-120,480,480],
    opacity: [0.2,0.4,0.6,0.8,1]
  },
  {
    width: 480,
    height: 320,
    viewBox: [0,-120,480,480],
    opacity: [0.2,0.4,0.6,0.8,1].reverse()
  },
  {
    width: 480,
    height: 320,
    viewBox: [0,-120,480,480].reverse(),
    opacity: [0.2,0.4,0.6,0.8,1].reverse()
  },
  {
    width: 480,
    height: 320,
    viewBox: [0,-120,480,480].reverse(),
    opacity: [0.2,0.4,0.6,0.8,1]
  },
  {
    width: 800,
    height: 800,
    viewBox: [0,-252,480,480],
    opacity: [0.2,0.4,0.6,0.8,1]
  },
  {
    width: 480,
    height: 320,
    viewBox: [0,-120,480,480].reverse(),
    opacity: [0.2,0.6,0.2,0.6,0.2]
  },
]

export const Ribbon = ({ palette }) => {
  const styles = css`
    svg {
      display: block;
    }
  `;

  const randomPaletteColor = palette[getRandomNumber(1, 3)];
  const { width, height, viewBox, opacity} = CONFIGURATIONS[getRandomNumber(0, CONFIGURATIONS.length - 1)];

  return (
    <div css={styles}>
      <svg
        width={width}
        height={height}
        viewBox={viewBox.join(" ")}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          opacity={opacity[0]}
          y="111.135"
          width="640"
          height="40"
          transform="rotate(-10 0 111.135)"
          fill={randomPaletteColor || "#D9D9D9"}
        />
        <rect
          opacity={opacity[1]}
          x="6.94531"
          y="150.527"
          width="640"
          height="40"
          transform="rotate(-10 6.94531 150.527)"
          fill={randomPaletteColor || "#D9D9D9"}
        />
        <rect
          opacity={opacity[2]}
          x="13.8926"
          y="189.919"
          width="640"
          height="40"
          transform="rotate(-10 13.8926 189.919)"
          fill={randomPaletteColor || "#D9D9D9"}
        />
        <rect
          opacity={opacity[3]}
          x="20.8379"
          y="229.312"
          width="640"
          height="40"
          transform="rotate(-10 20.8379 229.312)"
          fill={randomPaletteColor || "#D9D9D9"}
        />
        <rect
          opacity={opacity[4]}
          x="27.7832"
          y="268.704"
          width="640"
          height="40"
          transform="rotate(-10 27.7832 268.704)"
          fill={randomPaletteColor || "#D9D9D9"}
        />
      </svg>
    </div>
  );
};
