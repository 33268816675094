/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import { getRandomNumber } from "../utils";

const CONFIGURATIONS = [
  {
    width: 480,
    height: 480,
    viewBox: [320,-400,470,640]
  },
  {
    width: 240,
    height: 200,
    viewBox: [0,0,319,316]
  },
  {
    width: 400,
    height: 400,
    viewBox: [120,900,300,300]
  },
  {
    width: 400,
    height: 400,
    viewBox: [0,200,300,100]
  },
  {
    width: 200,
    height: 300,
    viewBox: [0,-100,550,500]
  },
]

export const Bands = ({ palette }) => {
  const styles = css`
    svg {
      display: block;
    }
  `;
  
  const paletteNumber = getRandomNumber(1,3)
  const { width, height, viewBox} = CONFIGURATIONS[getRandomNumber(0, CONFIGURATIONS.length - 1)];

  return (
    <div css={styles}>
      <svg
        width={width}
        height={height}
        viewBox={viewBox.join(" ")}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle opacity="0.3" cx="693" cy="693" r="693" fill={palette[paletteNumber] || "#d9d9d9"} />
        <circle opacity="0.3" cx="693" cy="693" r="649.688" fill={palette[paletteNumber] || "#d9d9d9"} />
        <circle opacity="0.3" cx="693" cy="693" r="606.375" fill={palette[paletteNumber] || "#d9d9d9"} />
        <circle opacity="0.3" cx="693" cy="693" r="563.062" fill={palette[paletteNumber] || "#d9d9d9"} />
        <circle opacity="0.3" cx="693" cy="693" r="519.75" fill={palette[paletteNumber] || "#d9d9d9"} />
        <circle opacity="0.3" cx="693" cy="693" r="476.438" fill={palette[paletteNumber] || "#d9d9d9"} />
        <circle opacity="0.3" cx="693" cy="693" r="433.125" fill={palette[paletteNumber] || "#d9d9d9"} />
        <circle opacity="0.3" cx="693" cy="693" r="389.812" fill={palette[paletteNumber] || "#d9d9d9"} />
        <circle opacity="0.3" cx="693" cy="693" r="346.5" fill={palette[paletteNumber] || "#d9d9d9"} />
        <circle opacity="0.3" cx="693" cy="693" r="303.188" fill={palette[paletteNumber] || "#d9d9d9"} />
        <circle opacity="0.3" cx="693" cy="693" r="259.875" fill={palette[paletteNumber] || "#d9d9d9"} />
        <circle opacity="0.3" cx="693" cy="693" r="216.562" fill={palette[paletteNumber] || "#d9d9d9"} />
        <circle opacity="0.3" cx="693" cy="693" r="173.25" fill={palette[paletteNumber] || "#d9d9d9"} />
        <circle opacity="0.3" cx="693" cy="693" r="129.938" fill={palette[paletteNumber] || "#d9d9d9"} />
        <circle opacity="0.3" cx="693" cy="693" r="86.625" fill={palette[paletteNumber] || "#d9d9d9"} />
        <circle opacity="0.3" cx="693" cy="693" r="43.3125" fill={palette[paletteNumber] || "#d9d9d9"} />
        <circle opacity="0.3" cx="693" cy="693" r="43.3125" fill={palette[paletteNumber] || "#d9d9d9"} />
      </svg>
    </div>
  );
};
