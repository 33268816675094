/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import { getRandomNumber } from "../utils";

const CONFIGURATIONS = [
  {
    width: 480,
    height: 480,
    viewBox: [-120,-240,480,480]
  },
  {
    width: 720,
    height: 720,
    viewBox: [-480,-480,720,720]
  },
  {
    width: 1700,
    height: 1700,
    viewBox: [-120,-240,480,480]
  },
  {
    width: 800,
    height: 800,
    viewBox: [-120,-240,480,480]
  }
]

export const Cone = ({ palette }) => {
  const styles = css`
    svg {
      display: block;
    }
  `;

  const randomPaletteColor = palette[getRandomNumber(1, 3)];
  const { width, height, viewBox} = CONFIGURATIONS[getRandomNumber(0, CONFIGURATIONS.length - 1)];

  return (
    <div css={styles}>
      <svg
        width={width}
        height={height}
        viewBox={viewBox.join(" ")}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          opacity="0.1"
          x="282.842"
          y="565.686"
          width="400"
          height="400"
          transform="rotate(-135 282.842 565.686)"
          fill={randomPaletteColor || "white"}
        />
        <rect
          opacity="0.1"
          x="282.842"
          y="565.686"
          width="368"
          height="368"
          transform="rotate(-135 282.842 565.686)"
          fill={randomPaletteColor || "white"}
        />
        <rect
          opacity="0.2"
          x="282.842"
          y="565.686"
          width="336"
          height="336"
          transform="rotate(-135 282.842 565.686)"
          fill={randomPaletteColor || "white"}
        />
        <rect
          opacity="0.3"
          x="282.842"
          y="565.686"
          width="304"
          height="304"
          transform="rotate(-135 282.842 565.686)"
          fill={randomPaletteColor || "white"}
        />
        <rect
          opacity="0.4"
          x="282.842"
          y="565.686"
          width="272"
          height="272"
          transform="rotate(-135 282.842 565.686)"
          fill={randomPaletteColor || "white"}
        />
        <rect
          opacity="0.6"
          x="282.842"
          y="565.686"
          width="240"
          height="240"
          transform="rotate(-135 282.842 565.686)"
          fill={randomPaletteColor || "white"}
        />
        <rect
          opacity="0.7"
          x="282.842"
          y="565.686"
          width="208"
          height="208"
          transform="rotate(-135 282.842 565.686)"
          fill={randomPaletteColor || "white"}
        />
      </svg>
    </div>
  );
};
