/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import { getRandomNumber } from "../utils";

const CONFIGURATIONS = [
  {
    width: 480,
    height: 480,
    viewBox: [12,-133,480,480],
    opacity: .8
  },
  {
    width: 800,
    height: 800,
    viewBox: [12,-133,480,480],
    opacity: .5
  },
  {
    width: 1200,
    height: 1200,
    viewBox: [180,-133,480,480],
    opacity: .5
  },
  {
    width: 480,
    height: 480,
    viewBox: [350,-133,480,480],
    opacity: .8
  },
  {
    width: 300,
    height: 200,
    viewBox: [700,70,200,480],
    opacity: 1
  },
  {
    width: 300,
    height: 200,
    viewBox: [12,70,200,480],
    opacity: 1
  },
]

export const Cross = ({ palette }) => {
  const styles = css`
    svg {
      display: block;
    }
  `;

  const randomPaletteColor = palette[getRandomNumber(1, 3)];
  const { width, height, viewBox, opacity} = CONFIGURATIONS[getRandomNumber(0, CONFIGURATIONS.length - 1)];

  return (
    <div css={styles}>
      <svg
        width={width}
        height={height}
        viewBox={viewBox.join(" ")}
        opacity={opacity}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M44.1951 544.153V563.795H54.0162V544.153H73.6585V534.332H54.0162V514.689H44.1951V534.332H24.5527V544.153H44.1951Z"
          fill={randomPaletteColor || "#D9D9D9"}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M93.3142 495.033V514.676H103.135V495.033H122.778V485.212H103.135V465.57H93.3142V485.212H73.6719V495.033H93.3142Z"
          fill={randomPaletteColor || "#D9D9D9"}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M142.433 445.915V465.557H152.255V445.915H171.897V436.093H152.255V416.451H142.433V436.093H122.791V445.915H142.433Z"
          fill={randomPaletteColor || "#D9D9D9"}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M191.552 396.795V416.437H201.374V396.795H221.016V386.974H201.374V367.332H191.552V386.974H171.91V396.795H191.552Z"
          fill={randomPaletteColor || "#D9D9D9"}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M240.658 347.689V367.331H250.479V347.689H270.121V337.868H250.479V318.226H240.658V337.868H221.016V347.689H240.658Z"
          fill={randomPaletteColor || "#D9D9D9"}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M289.765 298.583V318.225H299.587V298.583H319.229V288.761H299.587V269.119H289.765V288.761H270.123V298.583H289.765Z"
          fill={randomPaletteColor || "#D9D9D9"}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M338.873 249.476V269.118H348.694V249.476H368.336V239.655H348.694V220.013H338.873V239.655H319.23V249.476H338.873Z"
          fill={randomPaletteColor || "#D9D9D9"}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M387.736 200.611V220.253H397.557V200.611H417.2V190.79H397.557V171.147H387.736V190.79H368.094V200.611H387.736Z"
          fill={randomPaletteColor || "#D9D9D9"}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M436.601 151.746V171.388H446.422V151.746H466.065V141.925H446.422V122.283H436.601V141.925H416.959V151.746H436.601Z"
          fill={randomPaletteColor || "#D9D9D9"}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M485.467 102.881V122.523H495.288V102.881H514.93V93.0598H495.288V73.4175H485.467V93.0598H465.824V102.881H485.467Z"
          fill={randomPaletteColor || "#D9D9D9"}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M534.332 54.0162V73.6585H544.153V54.0162H563.795V44.1951H544.153V24.5527H534.332V44.1951H514.689V54.0162H534.332Z"
          fill={randomPaletteColor || "#D9D9D9"}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M93.4119 593.37V613.013H103.233V593.37H122.875V583.549H103.233V563.907H93.4119V583.549H73.7695V593.37H93.4119Z"
          fill={randomPaletteColor || "#D9D9D9"}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M142.531 544.251V563.893H152.352V544.251H171.994V534.429H152.352V514.787H142.531V534.429H122.889V544.251H142.531Z"
          fill={randomPaletteColor || "#D9D9D9"}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M191.65 495.132V514.774H201.471V495.132H221.114V485.311H201.471V465.668H191.65V485.311H172.008V495.132H191.65Z"
          fill={randomPaletteColor || "#D9D9D9"}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M240.769 446.012V465.655H250.59V446.012H270.233V436.191H250.59V416.549H240.769V436.191H221.127V446.012H240.769Z"
          fill={randomPaletteColor || "#D9D9D9"}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M289.875 396.906V416.549H299.696V396.906H319.338V387.085H299.696V367.443H289.875V387.085H270.232V396.906H289.875Z"
          fill={randomPaletteColor || "#D9D9D9"}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M338.982 347.8V367.442H348.803V347.8H368.446V337.979H348.803V318.336H338.982V337.979H319.34V347.8H338.982Z"
          fill={randomPaletteColor || "#D9D9D9"}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M388.09 298.693V318.336H397.911V298.693H417.553V288.872H397.911V269.23H388.09V288.872H368.447V298.693H388.09Z"
          fill={randomPaletteColor || "#D9D9D9"}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M436.953 249.828V269.471H446.774V249.828H466.416V240.007H446.774V220.365H436.953V240.007H417.311V249.828H436.953Z"
          fill={randomPaletteColor || "#D9D9D9"}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M485.818 200.963V220.606H495.639V200.963H515.282V191.142H495.639V171.5H485.818V191.142H466.176V200.963H485.818Z"
          fill={randomPaletteColor || "#D9D9D9"}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M534.683 152.098V171.741H544.505V152.098H564.147V142.277H544.505V122.635H534.683V142.277H515.041V152.098H534.683Z"
          fill={randomPaletteColor || "#D9D9D9"}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M583.549 103.233V122.876H593.37V103.233H613.012V93.4123H593.37V73.77H583.549V93.4123H563.906V103.233H583.549Z"
          fill={randomPaletteColor || "#D9D9D9"}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M142.629 642.587V662.229H152.45V642.587H172.092V632.766H152.45V613.124H142.629V632.766H122.986V642.587H142.629Z"
          fill={randomPaletteColor || "#D9D9D9"}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M191.748 593.467V613.11H201.569V593.467H221.211V583.646H201.569V564.004H191.748V583.646H172.105V593.467H191.748Z"
          fill={randomPaletteColor || "#D9D9D9"}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M240.867 544.349V563.991H250.688V544.349H270.33V534.528H250.688V514.885H240.867V534.528H221.225V544.349H240.867Z"
          fill={randomPaletteColor || "#D9D9D9"}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M289.986 495.229V514.871H299.807V495.229H319.45V485.408H299.807V465.766H289.986V485.408H270.344V495.229H289.986Z"
          fill={randomPaletteColor || "#D9D9D9"}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M339.092 446.123V465.765H348.913V446.123H368.555V436.302H348.913V416.66H339.092V436.302H319.449V446.123H339.092Z"
          fill={randomPaletteColor || "#D9D9D9"}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M388.199 397.017V416.659H398.02V397.017H417.662V387.196H398.02V367.553H388.199V387.196H368.557V397.017H388.199Z"
          fill={randomPaletteColor || "#D9D9D9"}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M437.306 347.91V367.553H447.128V347.91H466.77V338.089H447.128V318.447H437.306V338.089H417.664V347.91H437.306Z"
          fill={randomPaletteColor || "#D9D9D9"}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M486.17 299.045V318.687H495.991V299.045H515.633V289.224H495.991V269.582H486.17V289.224H466.527V299.045H486.17Z"
          fill={randomPaletteColor || "#D9D9D9"}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M535.035 250.18V269.823H544.856V250.18H564.498V240.359H544.856V220.717H535.035V240.359H515.393V250.18H535.035Z"
          fill={randomPaletteColor || "#D9D9D9"}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M583.9 201.315V220.957H593.721V201.315H613.364V191.494H593.721V171.852H583.9V191.494H564.258V201.315H583.9Z"
          fill={randomPaletteColor || "#D9D9D9"}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M632.765 152.45V172.093H642.587V152.45H662.229V142.629H642.587V122.987H632.765V142.629H613.123V152.45H632.765Z"
          fill={randomPaletteColor || "#D9D9D9"}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M191.845 691.804V711.447H201.667V691.804H221.309V681.983H201.667V662.341H191.845V681.983H172.203V691.804H191.845Z"
          fill={randomPaletteColor || "#D9D9D9"}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M240.965 642.685V662.327H250.786V642.685H270.428V632.864H250.786V613.221H240.965V632.864H221.322V642.685H240.965Z"
          fill={randomPaletteColor || "#D9D9D9"}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M290.084 593.566V613.208H299.905V593.566H319.547V583.745H299.905V564.103H290.084V583.745H270.441V593.566H290.084Z"
          fill={randomPaletteColor || "#D9D9D9"}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M339.203 544.446V564.089H349.024V544.446H368.666V534.625H349.024V514.983H339.203V534.625H319.561V544.446H339.203Z"
          fill={randomPaletteColor || "#D9D9D9"}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M388.308 495.34V514.983H398.13V495.34H417.772V485.519H398.13V465.877H388.308V485.519H368.666V495.34H388.308Z"
          fill={randomPaletteColor || "#D9D9D9"}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M437.416 446.234V465.876H447.237V446.234H466.879V436.413H447.237V416.771H437.416V436.413H417.773V446.234H437.416Z"
          fill={randomPaletteColor || "#D9D9D9"}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M486.523 397.128V416.77H496.344V397.128H515.987V387.306H496.344V367.664H486.523V387.306H466.881V397.128H486.523Z"
          fill={randomPaletteColor || "#D9D9D9"}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M535.386 348.262V367.905H545.208V348.262H564.85V338.441H545.208V318.799H535.386V338.441H515.744V348.262H535.386Z"
          fill={randomPaletteColor || "#D9D9D9"}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M584.252 299.398V319.04H594.073V299.398H613.715V289.576H594.073V269.934H584.252V289.576H564.609V299.398H584.252Z"
          fill={randomPaletteColor || "#D9D9D9"}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M633.117 250.532V270.175H642.938V250.532H662.58V240.711H642.938V221.069H633.117V240.711H613.475V250.532H633.117Z"
          fill={randomPaletteColor || "#D9D9D9"}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M681.982 201.668V221.31H691.803V201.668H711.446V191.846H691.803V172.204H681.982V191.846H662.34V201.668H681.982Z"
          fill={randomPaletteColor || "#D9D9D9"}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M241.064 741.022V760.664H250.885V741.022H270.528V731.2H250.885V711.558H241.064V731.2H221.422V741.022H241.064Z"
          fill={randomPaletteColor || "#D9D9D9"}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M290.183 691.902V711.544H300.005V691.902H319.647V682.081H300.005V662.438H290.183V682.081H270.541V691.902H290.183Z"
          fill={randomPaletteColor || "#D9D9D9"}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M339.302 642.783V662.426H349.124V642.783H368.766V632.962H349.124V613.32H339.302V632.962H319.66V642.783H339.302Z"
          fill={randomPaletteColor || "#D9D9D9"}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M388.422 593.664V613.306H398.243V593.664H417.885V583.843H398.243V564.2H388.422V583.843H368.779V593.664H388.422Z"
          fill={randomPaletteColor || "#D9D9D9"}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M437.527 544.558V564.2H447.348V544.558H466.991V534.737H447.348V515.094H437.527V534.737H417.885V544.558H437.527Z"
          fill={randomPaletteColor || "#D9D9D9"}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M486.635 495.451V515.094H496.456V495.451H516.098V485.63H496.456V465.988H486.635V485.63H466.992V495.451H486.635Z"
          fill={randomPaletteColor || "#D9D9D9"}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M535.742 446.345V465.987H545.563V446.345H565.205V436.524H545.563V416.881H535.742V436.524H516.1V446.345H535.742Z"
          fill={randomPaletteColor || "#D9D9D9"}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M584.605 397.48V417.122H594.426V397.48H614.069V387.658H594.426V368.016H584.605V387.658H564.963V397.48H584.605Z"
          fill={randomPaletteColor || "#D9D9D9"}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M633.47 348.615V368.257H643.292V348.615H662.934V338.794H643.292V319.151H633.47V338.794H613.828V348.615H633.47Z"
          fill={randomPaletteColor || "#D9D9D9"}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M682.336 299.75V319.392H692.157V299.75H711.799V289.928H692.157V270.286H682.336V289.928H662.693V299.75H682.336Z"
          fill={randomPaletteColor || "#D9D9D9"}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M731.201 250.885V270.527H741.022V250.885H760.664V241.064H741.022V221.421H731.201V241.064H711.559V250.885H731.201Z"
          fill={randomPaletteColor || "#D9D9D9"}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M290.281 790.238V809.881H300.102V790.238H319.744V780.417H300.102V760.775H290.281V780.417H270.639V790.238H290.281Z"
          fill={randomPaletteColor || "#D9D9D9"}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M339.4 741.119V760.761H349.221V741.119H368.864V731.298H349.221V711.655H339.4V731.298H319.758V741.119H339.4Z"
          fill={randomPaletteColor || "#D9D9D9"}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M388.519 692V711.642H398.34V692H417.983V682.179H398.34V662.537H388.519V682.179H368.877V692H388.519Z"
          fill={randomPaletteColor || "#D9D9D9"}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M437.638 642.88V662.523H447.46V642.88H467.102V633.059H447.46V613.417H437.638V633.059H417.996V642.88H437.638Z"
          fill={randomPaletteColor || "#D9D9D9"}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M486.744 593.775V613.417H496.565V593.775H516.207V583.953H496.565V564.311H486.744V583.953H467.102V593.775H486.744Z"
          fill={randomPaletteColor || "#D9D9D9"}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M535.851 544.668V564.31H545.672V544.668H565.315V534.847H545.672V515.205H535.851V534.847H516.209V544.668H535.851Z"
          fill={randomPaletteColor || "#D9D9D9"}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M584.959 495.562V515.204H594.78V495.562H614.422V485.74H594.78V466.098H584.959V485.74H565.316V495.562H584.959Z"
          fill={randomPaletteColor || "#D9D9D9"}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M633.822 446.696V466.339H643.643V446.696H663.285V436.875H643.643V417.233H633.822V436.875H614.18V446.696H633.822Z"
          fill={randomPaletteColor || "#D9D9D9"}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M682.687 397.832V417.474H692.508V397.832H712.151V388.01H692.508V368.368H682.687V388.01H663.045V397.832H682.687Z"
          fill={randomPaletteColor || "#D9D9D9"}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M731.552 348.966V368.609H741.374V348.966H761.016V339.145H741.374V319.503H731.552V339.145H711.91V348.966H731.552Z"
          fill={randomPaletteColor || "#D9D9D9"}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M780.418 300.102V319.744H790.239V300.102H809.881V290.281H790.239V270.638H780.418V290.281H760.775V300.102H780.418Z"
          fill={randomPaletteColor || "#D9D9D9"}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M339.498 839.456V859.098H349.319V839.456H368.961V829.635H349.319V809.992H339.498V829.635H319.855V839.456H339.498Z"
          fill={randomPaletteColor || "#D9D9D9"}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M388.617 790.336V809.978H398.438V790.336H418.08V780.515H398.438V760.873H388.617V780.515H368.975V790.336H388.617Z"
          fill={randomPaletteColor || "#D9D9D9"}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M437.736 741.217V760.86H447.557V741.217H467.2V731.396H447.557V711.754H437.736V731.396H418.094V741.217H437.736Z"
          fill={randomPaletteColor || "#D9D9D9"}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M486.855 692.098V711.74H496.676V692.098H516.319V682.277H496.676V662.634H486.855V682.277H467.213V692.098H486.855Z"
          fill={randomPaletteColor || "#D9D9D9"}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M535.961 642.992V662.634H545.782V642.992H565.424V633.171H545.782V613.528H535.961V633.171H516.318V642.992H535.961Z"
          fill={randomPaletteColor || "#D9D9D9"}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M585.068 593.885V613.528H594.889V593.885H614.532V584.064H594.889V564.422H585.068V584.064H565.426V593.885H585.068Z"
          fill={randomPaletteColor || "#D9D9D9"}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M634.176 544.779V564.421H643.997V544.779H663.639V534.958H643.997V515.315H634.176V534.958H614.533V544.779H634.176Z"
          fill={randomPaletteColor || "#D9D9D9"}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M683.039 495.914V515.556H692.86V495.914H712.502V486.093H692.86V466.45H683.039V486.093H663.396V495.914H683.039Z"
          fill={randomPaletteColor || "#D9D9D9"}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M731.904 447.049V466.691H741.725V447.049H761.367V437.228H741.725V417.585H731.904V437.228H712.262V447.049H731.904Z"
          fill={randomPaletteColor || "#D9D9D9"}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M780.769 398.184V417.826H790.59V398.184H810.233V388.363H790.59V368.72H780.769V388.363H761.127V398.184H780.769Z"
          fill={randomPaletteColor || "#D9D9D9"}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M829.635 349.319V368.961H839.456V349.319H859.098V339.498H839.456V319.855H829.635V339.498H809.992V349.319H829.635Z"
          fill={randomPaletteColor || "#D9D9D9"}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M388.715 888.673V908.315H398.536V888.673H418.178V878.852H398.536V859.209H388.715V878.852H369.072V888.673H388.715Z"
          fill={randomPaletteColor || "#D9D9D9"}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M437.834 839.553V859.196H447.655V839.553H467.297V829.732H447.655V810.09H437.834V829.732H418.191V839.553H437.834Z"
          fill={randomPaletteColor || "#D9D9D9"}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M486.953 790.435V810.077H496.774V790.435H516.416V780.614H496.774V760.971H486.953V780.614H467.311V790.435H486.953Z"
          fill={randomPaletteColor || "#D9D9D9"}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M536.072 741.315V760.957H545.893V741.315H565.535V731.494H545.893V711.852H536.072V731.494H516.43V741.315H536.072Z"
          fill={randomPaletteColor || "#D9D9D9"}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M585.177 692.209V711.851H594.999V692.209H614.641V682.388H594.999V662.746H585.177V682.388H565.535V692.209H585.177Z"
          fill={randomPaletteColor || "#D9D9D9"}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M634.285 643.103V662.745H644.106V643.103H663.748V633.281H644.106V613.639H634.285V633.281H614.643V643.103H634.285Z"
          fill={randomPaletteColor || "#D9D9D9"}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M683.392 593.996V613.638H693.213V593.996H712.856V584.175H693.213V564.533H683.392V584.175H663.75V593.996H683.392Z"
          fill={randomPaletteColor || "#D9D9D9"}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M732.256 545.131V564.773H742.077V545.131H761.719V535.31H742.077V515.667H732.256V535.31H712.613V545.131H732.256Z"
          fill={randomPaletteColor || "#D9D9D9"}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M781.121 496.266V515.909H790.942V496.266H810.584V486.445H790.942V466.803H781.121V486.445H761.479V496.266H781.121Z"
          fill={randomPaletteColor || "#D9D9D9"}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M829.986 447.401V467.043H839.807V447.401H859.45V437.58H839.807V417.938H829.986V437.58H810.344V447.401H829.986Z"
          fill={randomPaletteColor || "#D9D9D9"}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M878.851 398.536V418.179H888.672V398.536H908.315V388.715H888.672V369.073H878.851V388.715H859.209V398.536H878.851Z"
          fill={randomPaletteColor || "#D9D9D9"}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M437.931 937.89V957.532H447.753V937.89H467.395V928.069H447.753V908.426H437.931V928.069H418.289V937.89H437.931Z"
          fill={randomPaletteColor || "#D9D9D9"}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M487.051 888.77V908.412H496.872V888.77H516.514V878.949H496.872V859.307H487.051V878.949H467.408V888.77H487.051Z"
          fill={randomPaletteColor || "#D9D9D9"}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M536.17 839.651V859.294H545.991V839.651H565.633V829.83H545.991V810.188H536.17V829.83H516.527V839.651H536.17Z"
          fill={randomPaletteColor || "#D9D9D9"}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M585.289 790.532V810.174H595.11V790.532H614.752V780.711H595.11V761.068H585.289V780.711H565.646V790.532H585.289Z"
          fill={randomPaletteColor || "#D9D9D9"}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M634.394 741.426V761.068H644.215V741.426H663.858V731.605H644.215V711.962H634.394V731.605H614.752V741.426H634.394Z"
          fill={randomPaletteColor || "#D9D9D9"}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M683.502 692.319V711.962H693.323V692.319H712.965V682.498H693.323V662.856H683.502V682.498H663.859V692.319H683.502Z"
          fill={randomPaletteColor || "#D9D9D9"}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M732.609 643.213V662.855H742.43V643.213H762.073V633.392H742.43V613.75H732.609V633.392H712.967V643.213H732.609Z"
          fill={randomPaletteColor || "#D9D9D9"}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M781.472 594.348V613.99H791.294V594.348H810.936V584.527H791.294V564.884H781.472V584.527H761.83V594.348H781.472Z"
          fill={randomPaletteColor || "#D9D9D9"}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M830.338 545.483V565.125H840.159V545.483H859.801V535.662H840.159V516.02H830.338V535.662H810.695V545.483H830.338Z"
          fill={randomPaletteColor || "#D9D9D9"}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M879.203 496.618V516.26H889.024V496.618H908.666V486.797H889.024V467.154H879.203V486.797H859.561V496.618H879.203Z"
          fill={randomPaletteColor || "#D9D9D9"}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M928.068 447.753V467.395H937.889V447.753H957.532V437.932H937.889V418.29H928.068V437.932H908.426V447.753H928.068Z"
          fill={randomPaletteColor || "#D9D9D9"}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M487.148 987.107V1006.75H496.969V987.107H516.612V977.286H496.969V957.644H487.148V977.286H467.506V987.107H487.148Z"
          fill={randomPaletteColor || "#D9D9D9"}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M536.267 937.987V957.63H546.088V937.987H565.731V928.166H546.088V908.524H536.267V928.166H516.625V937.987H536.267Z"
          fill={randomPaletteColor || "#D9D9D9"}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M585.386 888.869V908.511H595.208V888.869H614.85V879.048H595.208V859.405H585.386V879.048H565.744V888.869H585.386Z"
          fill={randomPaletteColor || "#D9D9D9"}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M634.506 839.749V859.391H644.327V839.749H663.969V829.928H644.327V810.286H634.506V829.928H614.863V839.749H634.506Z"
          fill={randomPaletteColor || "#D9D9D9"}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M683.611 790.643V810.285H693.432V790.643H713.075V780.822H693.432V761.18H683.611V780.822H663.969V790.643H683.611Z"
          fill={randomPaletteColor || "#D9D9D9"}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M732.719 741.537V761.179H742.54V741.537H762.182V731.716H742.54V712.073H732.719V731.716H713.076V741.537H732.719Z"
          fill={randomPaletteColor || "#D9D9D9"}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M781.826 692.43V712.073H791.647V692.43H811.289V682.609H791.647V662.967H781.826V682.609H762.184V692.43H781.826Z"
          fill={randomPaletteColor || "#D9D9D9"}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M830.689 643.565V663.207H840.51V643.565H860.153V633.744H840.51V614.102H830.689V633.744H811.047V643.565H830.689Z"
          fill={randomPaletteColor || "#D9D9D9"}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M879.554 594.7V614.343H889.376V594.7H909.018V584.879H889.376V565.237H879.554V584.879H859.912V594.7H879.554Z"
          fill={randomPaletteColor || "#D9D9D9"}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M928.42 545.835V565.477H938.241V545.835H957.883V536.014H938.241V516.372H928.42V536.014H908.777V545.835H928.42Z"
          fill={randomPaletteColor || "#D9D9D9"}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M977.285 496.97V516.613H987.106V496.97H1006.75V487.149H987.106V467.507H977.285V487.149H957.643V496.97H977.285Z"
          fill={randomPaletteColor || "#D9D9D9"}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M536.367 1036.32V1055.97H546.188V1036.32H565.83V1026.5H546.188V1006.86H536.367V1026.5H516.725V1036.32H536.367Z"
          fill={randomPaletteColor || "#D9D9D9"}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M585.486 987.205V1006.85H595.307V987.205H614.95V977.384H595.307V957.741H585.486V977.384H565.844V987.205H585.486Z"
          fill={randomPaletteColor || "#D9D9D9"}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M634.605 938.086V957.728H644.426V938.086H664.069V928.265H644.426V908.623H634.605V928.265H614.963V938.086H634.605Z"
          fill={randomPaletteColor || "#D9D9D9"}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M683.724 888.966V908.609H693.546V888.966H713.188V879.145H693.546V859.503H683.724V879.145H664.082V888.966H683.724Z"
          fill={randomPaletteColor || "#D9D9D9"}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M732.83 839.86V859.503H742.651V839.86H762.293V830.039H742.651V810.397H732.83V830.039H713.188V839.86H732.83Z"
          fill={randomPaletteColor || "#D9D9D9"}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M781.937 790.754V810.396H791.758V790.754H811.401V780.933H791.758V761.291H781.937V780.933H762.295V790.754H781.937Z"
          fill={randomPaletteColor || "#D9D9D9"}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M831.045 741.648V761.29H840.866V741.648H860.508V731.826H840.866V712.184H831.045V731.826H811.402V741.648H831.045Z"
          fill={randomPaletteColor || "#D9D9D9"}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M879.908 692.782V712.425H889.729V692.782H909.371V682.961H889.729V663.319H879.908V682.961H860.266V692.782H879.908Z"
          fill={randomPaletteColor || "#D9D9D9"}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M928.773 643.918V663.56H938.594V643.918H958.237V634.096H938.594V614.454H928.773V634.096H909.131V643.918H928.773Z"
          fill={randomPaletteColor || "#D9D9D9"}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M977.638 595.052V614.695H987.46V595.052H1007.1V585.231H987.46V565.589H977.638V585.231H957.996V595.052H977.638Z"
          fill={randomPaletteColor || "#D9D9D9"}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1026.5 546.188V565.83H1036.32V546.188H1055.97V536.366H1036.32V516.724H1026.5V536.366H1006.86V546.188H1026.5Z"
          fill={randomPaletteColor || "#D9D9D9"}
        />
      </svg>
    </div>
  );
};
