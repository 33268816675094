/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import { getRandomNumber } from "../utils";

const CONFIGURATIONS = [
  {
    width: 480,
    height: 480,
    viewBox: [100,-280,480,480],
    opacity: .8
  },
  {
    width: 2400,
    height: 2400,
    viewBox: [100,-280,480,480],
    opacity: .9
  },
  {
    width: 600,
    height: 600,
    viewBox: [0,0,120,640],
    opacity: .3
  },
  {
    width: 240,
    height: 200,
    viewBox: [260,0,200,800],
    opacity: .8
  }
]

export const Radar = ({ palette }) => {
  const styles = css`
    svg {
      display: block;
    }
  `;

  const randomPaletteColor = palette[getRandomNumber(1, 3)];
  const { width, height, viewBox, opacity} = CONFIGURATIONS[getRandomNumber(0, CONFIGURATIONS.length - 1)];
  // const { width, height, viewBox, opacity } = CONFIGURATIONS[2];

  return (
    <div css={styles}>
      <svg
        width={width}
        height={height}
        viewBox={viewBox}
        opacity={opacity}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M332 664C515.359 664 664 515.359 664 332C664 148.641 515.359 0 332 0C148.641 0 0 148.641 0 332C0 515.359 148.641 664 332 664ZM331.999 627.111C494.985 627.111 627.11 494.985 627.11 332C627.11 169.015 494.985 36.889 331.999 36.889C169.014 36.889 36.8881 169.015 36.8881 332C36.8881 494.985 169.014 627.111 331.999 627.111Z"
          fill={randomPaletteColor || "#D9D9D9"}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M332 590.222C474.612 590.222 590.222 474.612 590.222 332C590.222 189.388 474.612 73.7778 332 73.7778C189.387 73.7778 73.7773 189.388 73.7773 332C73.7773 474.612 189.387 590.222 332 590.222ZM331.999 553.333C454.238 553.333 553.332 454.239 553.332 332C553.332 209.761 454.238 110.667 331.999 110.667C209.76 110.667 110.665 209.761 110.665 332C110.665 454.239 209.76 553.333 331.999 553.333Z"
          fill={randomPaletteColor || "#D9D9D9"}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M332.001 516.445C433.867 516.445 516.446 433.866 516.446 332.001C516.446 230.135 433.867 147.556 332.001 147.556C230.135 147.556 147.557 230.135 147.557 332.001C147.557 433.866 230.135 516.445 332.001 516.445ZM332 479.556C413.493 479.556 479.556 413.493 479.556 332.001C479.556 250.508 413.493 184.445 332 184.445C250.508 184.445 184.445 250.508 184.445 332.001C184.445 413.493 250.508 479.556 332 479.556Z"
          fill={randomPaletteColor || "#D9D9D9"}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M331.999 442.666C393.118 442.666 442.665 393.119 442.665 332C442.665 270.88 393.118 221.333 331.999 221.333C270.879 221.333 221.332 270.88 221.332 332C221.332 393.119 270.879 442.666 331.999 442.666ZM331.998 405.778C372.744 405.778 405.776 372.746 405.776 332C405.776 291.253 372.744 258.222 331.998 258.222C291.252 258.222 258.22 291.253 258.22 332C258.22 372.746 291.252 405.778 331.998 405.778Z"
          fill={randomPaletteColor || "#D9D9D9"}
        />
        <circle cx="332" cy="332" r="37" fill={randomPaletteColor || "#D9D9D9"} />
      </svg>
    </div>
  );
};
