/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import { getRandomNumber } from "../utils";

const CONFIGURATIONS = [
  {
    width: 480,
    height: 480,
    viewBox: [-200,-300,480,480]
  },
  {
    width: 1200,
    height: 1200,
    viewBox: [-200,-300,480,480]
  },
]

export const GradBarsVert = ({ palette }) => {
  const styles = css`
    svg {
      display: block;
    }
  `;
  
  const randomPaletteColor = palette[getRandomNumber(1, 3)];
  const { width, height, viewBox} = CONFIGURATIONS[getRandomNumber(0, CONFIGURATIONS.length - 1)];

  return (
    <div css={styles}>
      <svg
        width={width}
        height={height}
        viewBox={viewBox.join(" ")}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect opacity="0.1" width="128" height="200" fill={randomPaletteColor || "#D9D9D9"} />
        <rect opacity="0.2" x="136" width="64" height="200" fill={randomPaletteColor || "#D9D9D9"} />
        <rect opacity="0.3" x="208" width="32" height="200" fill={randomPaletteColor || "#D9D9D9"} />
        <rect opacity="0.4" x="248" width="16" height="200" fill={randomPaletteColor || "#D9D9D9"} />
        <rect opacity="0.5" x="272" width="8" height="200" fill={randomPaletteColor || "#D9D9D9"} />
        <rect opacity="0.6" x="288" width="4" height="200" fill={randomPaletteColor || "#D9D9D9"} />
      </svg>
    </div>
  );
};
