/** @jsx jsx */
import { jsx, css } from "@emotion/react";

export const BarsVert = ({ palette }) => {
  const styles = css`
    svg {
      display: block;
    }
  `;
  return (
    <div css={styles}>
      <svg
        width="480"
        height="480"
        viewBox="-200 -320 480 480"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="160" height="200" fill={palette[1] || "#D9D9D9"} />
        <rect x="168" width="80" height="200" fill={palette[2] || "#D9D9D9"} />
        <rect x="256" width="40" height="200" fill={palette[3] || "#D9D9D9"} />
      </svg>
    </div>
  );
};
