/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import { useEffect, useState, useMemo } from "react";
import ColorContrastChecker from "color-contrast-checker";
import colorString from "color-string";

import {
  Asterisk,
  Bands,
  Bars,
  BarsVert,
  Circle,
  Comb,
  Cone,
  Corner,
  Cross,
  FlatBars,
  GradBarsVert,
  Horizon,
  Radar,
  Ribbon,
  Triangle,
} from "./shapes";

import NoiseBG from "../../../../../images/noise.png";

const getHex = (rgb) => colorString.to.hex(rgb);

const textContrast = (palette) => {
  const ccc = new ColorContrastChecker();
  const color = palette.slice(1).filter((item) => {
    const background = getHex(palette[0]);
    return ccc.isLevelAAA(background, colorString.to.hex(item), 16);
  })[0];

  if (color) {
    return color;
  } else {
    // Figure out if black or white, based on root color
    return colorString.get.rgb(
      ccc.isLevelAAA("#121212", colorString.to.hex(palette[0]), 16)
        ? "#121212"
        : "#FFFFFF"
    );
  }
};

const Book = ({ hit }) => {
  const randomCover = () => {
    const covers = [
      <Asterisk palette={hit.palette.map(getHex)} />,
      <Bands palette={hit.palette.map(getHex)} />,
      <Bars palette={hit.palette.map(getHex)} />,
      <BarsVert palette={hit.palette.map(getHex)} />,
      <Circle palette={hit.palette.map(getHex)} />,
      <Comb palette={hit.palette.map(getHex)} />,
      <Cone palette={hit.palette.map(getHex)} />,
      <Corner palette={hit.palette.map(getHex)} />,
      <Cross palette={hit.palette.map(getHex)} />,
      <FlatBars palette={hit.palette.map(getHex)} />,
      <GradBarsVert palette={hit.palette.map(getHex)} />,
      <Horizon palette={hit.palette.map(getHex)} />,
      <Radar palette={hit.palette.map(getHex)} />,
      <Ribbon palette={hit.palette.map(getHex)} />,
      <Triangle palette={hit.palette.map(getHex)} />,
    ];
    return covers[Math.floor(Math.random() * covers.length)];
  };

  const [textColor, setTextColor] = useState([255, 255, 255]);

  useEffect(() => {
    setTextColor(textContrast(hit.palette));
  }, []);

  const styles = css`
    width: 100%;
    background: rgba(
      ${hit.palette[0][0]},
      ${hit.palette[0][1]},
      ${hit.palette[0][2]},
      1
    );

    color: rgba(${textColor[0]}, ${textColor[1]}, ${textColor[2]});
    aspect-ratio: 0.72;
    height: 100%;
    overflow: hidden;
    position: relative;
    box-shadow: 0 10px 20px -10px rgba(0, 0, 0, 1);
    transition: background 2s ease-in-out, opacity 0.3s ease-in-out,
      color 0.3s ease-in-out;
    z-index: 2;
    border-radius: 3px;
    opacity: 1;

    .head {
      padding: 24px 48px 24px 40px;
      position: relative;
      z-index: 4;
      line-height: 1.1;
    }

    .border {
      position: absolute;
      top: 0;
      left: 0;
      width: 20px;
      height: 100%;
      background: rgba(
        ${hit.palette[0][0]},
        ${hit.palette[0][1]},
        ${hit.palette[0][2]},
        0
      );
      box-shadow: inset -5px 0 5px rgba(0, 0, 0, 0.15),
        5px 0 5px rgba(0, 0, 0, 0.08);
      mix-blend-mode: hard-light;
    }

    .grad {
      background: linear-gradient(
        135deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(0, 0, 0, 1) 100%
      );
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      mix-blend-mode: hard-light;
      opacity: 0.1;
    }

    .image {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      overflow: hidden;
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
    }

    .t4 {
      margin-bottom: 8px;
      &:first-of-type {
        border-bottom: 1px solid
          rgba(${textColor[0]}, ${textColor[1]}, ${textColor[2]}, 0.3);

        padding-bottom: 8px;
      }

      &:last-of-type {
        font-size: 1rem;
        font-weight: 700;
      }
    }

    .noise {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      mix-blend-mode: multiply;
      opacity: 0.06;
    }
  `;
  return (
    <div css={styles}>
      <div className="underlay" />
      <div className="head">
        <div className="author t4">{hit.author}</div>
        <div className="title t4">{hit.title}</div>
      </div>
      <div className="image">{useMemo(randomCover, [])}</div>
      <div className="border" />
      <div className="grad" />
      <img className="noise" src={NoiseBG} alt="" />
    </div>
  );
};

export { Book };
