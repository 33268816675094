/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import { getRandomNumber } from "../utils";

const CONFIGURATIONS = [
  {
    width: 480,
    height: 260,
    viewBox: [-130,0,480,420]
  },
  {
    width: 480,
    height: 200,
    viewBox: [200,0,600,200]
  },
  {
    width: 1000,
    height: 800,
    viewBox: [200,100,200,200]
  },
]

export const Bars = ({ palette }) => {
  const styles = css`
    svg {
      display: block;
    }
  `;

  const palette1 = getRandomNumber(1, 3);
  const palette2 = getRandomNumber(1, 3);

  const {width, height, viewBox} = CONFIGURATIONS[getRandomNumber(0, CONFIGURATIONS.length - 1)];

  return (
    <div css={styles}>
      <svg
        width={width}
        height={height}
        viewBox={viewBox.join(" ")}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="22.627"
          y="486.489"
          width="40"
          height="320"
          transform="rotate(-45 22.627 486.489)"
          fill={palette[palette1] || '#D9D9D9'}
        />
        <rect
          x="101.824"
          y="407.293"
          width="40"
          height="320"
          transform="rotate(-45 101.824 407.293)"
          fill={palette[palette1] || '#D9D9D9'}
        />
        <rect
          x="181.02"
          y="328.097"
          width="40"
          height="320"
          transform="rotate(-45 181.02 328.097)"
          fill={palette[palette1] || '#D9D9D9'}
        />
        <rect
          x="260.215"
          y="248.901"
          width="40"
          height="320"
          transform="rotate(-45 260.215 248.901)"
          fill={palette[palette1] || '#D9D9D9'}
        />
        <rect
          x="339.412"
          y="169.706"
          width="40"
          height="320"
          transform="rotate(-45 339.412 169.706)"
          fill={palette[palette1] || '#D9D9D9'}
        />
        <rect
          x="418.607"
          y="90.5093"
          width="40"
          height="320"
          transform="rotate(-45 418.607 90.5093)"
          fill={palette[palette1] || '#D9D9D9'}
        />
        <rect
          x="39.5977"
          y="424.264"
          width="40"
          height="320"
          transform="rotate(-45 39.5977 424.264)"
          fill={palette[palette2] || '#D9D9D9'}
        />
        <rect
          x="118.793"
          y="345.068"
          width="40"
          height="320"
          transform="rotate(-45 118.793 345.068)"
          fill={palette[palette2] || '#D9D9D9'}
        />
        <rect
          x="197.99"
          y="265.872"
          width="40"
          height="320"
          transform="rotate(-45 197.99 265.872)"
          fill={palette[palette2] || '#D9D9D9'}
        />
        <rect
          x="277.186"
          y="186.676"
          width="40"
          height="320"
          transform="rotate(-45 277.186 186.676)"
          fill={palette[palette2] || '#D9D9D9'}
        />
        <rect
          x="356.381"
          y="107.48"
          width="40"
          height="320"
          transform="rotate(-45 356.381 107.48)"
          fill={palette[palette2] || '#D9D9D9'}
        />
        <rect
          x="435.578"
          y="28.2842"
          width="40"
          height="320"
          transform="rotate(-45 435.578 28.2842)"
          fill={palette[palette2] || '#D9D9D9'}
        />
      </svg>
    </div>
  );
};
