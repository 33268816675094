/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import { getRandomNumber } from "../utils";

const CONFIGURATIONS = [
  {
    width: 320,
    height: 135,
    viewBox: [0,0,260,135]
  },
  {
    width: 280,
    height: 200,
    viewBox: [80,0,80,135]
  }
]

export const Horizon = ({ palette }) => {
  const styles = css`
    svg {
      display: block;
    }
  `;
  
  const randomPaletteColor = palette[getRandomNumber(1, 3)];
  const { width, height, viewBox} = CONFIGURATIONS[getRandomNumber(0, CONFIGURATIONS.length - 1)];

  return (
    <div css={styles}>
      <svg
        width={width}
        height={height}
        viewBox={viewBox.join(" ")}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect y="103" width="296" height="32" fill={randomPaletteColor || "#d9d9d9"} />
        <rect y="71" width="296" height="24" fill={randomPaletteColor || "#d9d9d9"} />
        <rect y="47" width="296" height="16" fill={randomPaletteColor || "#d9d9d9"} />
        <rect y="31" width="296" height="8" fill={randomPaletteColor || "#d9d9d9"} />
        <rect y="19" width="296" height="4" fill={randomPaletteColor || "#d9d9d9"} />
        <rect y="9" width="296" height="2" fill={randomPaletteColor || "#d9d9d9"} />
        <rect width="296" height="1" fill={randomPaletteColor || "#d9d9d9"} />
      </svg>
    </div>
  );
};
