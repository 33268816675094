/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import { getRandomNumber} from '../utils'

const CONFIGURATIONS = [
  {
    width: 240,
    height: 200,
    viewBox: [0,0,319,316]
  },
  {
    width: 300,
    height: 300,
    viewBox: [0,0,300,100]
  },
  {
    width: 200,
    height: 300,
    viewBox: [0,-100,550,500]
  },
]



export const Asterisk = ({ palette }) => {
  
  const styles = css`
    svg {
      display: block;
    }
  `;
  const {width, height, viewBox} = CONFIGURATIONS[getRandomNumber(0, CONFIGURATIONS.length - 1)];
  return (
    <div css={styles}>
      <svg
        width={width}
        height={height}
        viewBox={viewBox.join(" ")}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.334 268.893L99.903 217.18L0 190.411L20.7055 113.137L120.044 139.755L68.5371 50.5417L137.819 10.5417L189.743 100.477L216.666 0L293.94 20.7055L267.172 120.607L356.744 68.8925L396.744 138.175L307.165 189.893L407.076 216.664L386.37 293.938L285.895 267.016L337.819 356.952L268.537 396.952L217.03 307.739L190.413 407.076L113.139 386.37L139.91 286.458L50.334 338.175L10.334 268.893Z"
          fill={palette[getRandomNumber(1,3)] || "#D9D9D9"}
        />
      </svg>
    </div>
  );
};
