/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import { getRandomNumber } from "../utils";

const CONFIGURATIONS = [
  {
    width: 480,
    height: 280,
    viewBox: [-44,0,480,480],
    opacity: .5
  },
  {
    width: 480,
    height: 280,
    viewBox: [-44,0,480,480].reverse(),
    opacity: .5,
  },
  {
    width: 900,
    height: 900,
    viewBox: [-20,-10,480,480],
    opacity: .2,
  },
  {
    width: 292,
    height: 80,
    viewBox: [-20,40,420,420].reverse(),
    opacity: 1,
  }
]

export const Triangle = ({ palette }) => {
  const styles = css`
    svg {
      display: block;
    }
  `;
  
  const randomPaletteColor = palette[getRandomNumber(1, 3)];
  const { width, height, viewBox, opacity} = CONFIGURATIONS[getRandomNumber(0, CONFIGURATIONS.length - 1)];

  return (
    <div css={styles}>
      <svg
        width={width}
        height={height}
        viewBox={viewBox.join(" ")}
        opacity={opacity}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M80 80V0L0 80H80Z" fill={randomPaletteColor || "#d9d9d9"} />
        <path d="M80 160V80L0 160H80Z" fill={randomPaletteColor || "#d9d9d9"} />
        <path d="M80 240V160L0 240H80Z" fill={randomPaletteColor || "#d9d9d9"} />
        <path d="M80 320V240L0 320H80Z" fill={randomPaletteColor || "#d9d9d9"} />
        <path d="M80 400V320L0 400H80Z" fill={randomPaletteColor || "#d9d9d9"} />
        <path d="M80 480V400L0 480H80Z" fill={randomPaletteColor || "#d9d9d9"} />
        <path d="M80 560V480L0 560H80Z" fill={randomPaletteColor || "#d9d9d9"} />
        <path d="M80 640V560L0 640H80Z" fill={randomPaletteColor || "#d9d9d9"} />
        <path d="M80 720V640L0 720H80Z" fill={randomPaletteColor || "#d9d9d9"} />
        <path d="M80 800V720L0 800H80Z" fill={randomPaletteColor || "#d9d9d9"} />
        <path d="M80 880V800L0 880H80Z" fill={randomPaletteColor || "#d9d9d9"} />
        <path d="M160 80V0L80 80H160Z" fill={randomPaletteColor || "#d9d9d9"} />
        <path d="M160 160V80L80 160H160Z" fill={randomPaletteColor || "#d9d9d9"} />
        <path d="M160 240V160L80 240H160Z" fill={randomPaletteColor || "#d9d9d9"} />
        <path d="M160 320V240L80 320H160Z" fill={randomPaletteColor || "#d9d9d9"} />
        <path d="M160 400V320L80 400H160Z" fill={randomPaletteColor || "#d9d9d9"} />
        <path d="M160 480V400L80 480H160Z" fill={randomPaletteColor || "#d9d9d9"} />
        <path d="M160 560V480L80 560H160Z" fill={randomPaletteColor || "#d9d9d9"} />
        <path d="M160 640V560L80 640H160Z" fill={randomPaletteColor || "#d9d9d9"} />
        <path d="M160 720V640L80 720H160Z" fill={randomPaletteColor || "#d9d9d9"} />
        <path d="M160 800V720L80 800H160Z" fill={randomPaletteColor || "#d9d9d9"} />
        <path d="M160 880V800L80 880H160Z" fill={randomPaletteColor || "#d9d9d9"} />
        <path d="M240 80V0L160 80H240Z" fill={randomPaletteColor || "#d9d9d9"} />
        <path d="M240 160V80L160 160H240Z" fill={randomPaletteColor || "#d9d9d9"} />
        <path d="M240 240V160L160 240H240Z" fill={randomPaletteColor || "#d9d9d9"} />
        <path d="M240 320V240L160 320H240Z" fill={randomPaletteColor || "#d9d9d9"} />
        <path d="M240 400V320L160 400H240Z" fill={randomPaletteColor || "#d9d9d9"} />
        <path d="M240 480V400L160 480H240Z" fill={randomPaletteColor || "#d9d9d9"} />
        <path d="M240 560V480L160 560H240Z" fill={randomPaletteColor || "#d9d9d9"} />
        <path d="M240 640V560L160 640H240Z" fill={randomPaletteColor || "#d9d9d9"} />
        <path d="M240 720V640L160 720H240Z" fill={randomPaletteColor || "#d9d9d9"} />
        <path d="M240 800V720L160 800H240Z" fill={randomPaletteColor || "#d9d9d9"} />
        <path d="M240 880V800L160 880H240Z" fill={randomPaletteColor || "#d9d9d9"} />
        <path d="M320 80V0L240 80H320Z" fill={randomPaletteColor || "#d9d9d9"} />
        <path d="M320 160V80L240 160H320Z" fill={randomPaletteColor || "#d9d9d9"} />
        <path d="M320 240V160L240 240H320Z" fill={randomPaletteColor || "#d9d9d9"} />
        <path d="M320 320V240L240 320H320Z" fill={randomPaletteColor || "#d9d9d9"} />
        <path d="M320 400V320L240 400H320Z" fill={randomPaletteColor || "#d9d9d9"} />
        <path d="M320 480V400L240 480H320Z" fill={randomPaletteColor || "#d9d9d9"} />
        <path d="M320 560V480L240 560H320Z" fill={randomPaletteColor || "#d9d9d9"} />
        <path d="M320 640V560L240 640H320Z" fill={randomPaletteColor || "#d9d9d9"} />
        <path d="M320 720V640L240 720H320Z" fill={randomPaletteColor || "#d9d9d9"} />
        <path d="M320 800V720L240 800H320Z" fill={randomPaletteColor || "#d9d9d9"} />
        <path d="M320 880V800L240 880H320Z" fill={randomPaletteColor || "#d9d9d9"} />
        <path d="M400 80V0L320 80H400Z" fill={randomPaletteColor || "#d9d9d9"} />
        <path d="M400 160V80L320 160H400Z" fill={randomPaletteColor || "#d9d9d9"} />
        <path d="M400 240V160L320 240H400Z" fill={randomPaletteColor || "#d9d9d9"} />
        <path d="M400 320V240L320 320H400Z" fill={randomPaletteColor || "#d9d9d9"} />
        <path d="M400 400V320L320 400H400Z" fill={randomPaletteColor || "#d9d9d9"} />
        <path d="M400 480V400L320 480H400Z" fill={randomPaletteColor || "#d9d9d9"} />
        <path d="M400 560V480L320 560H400Z" fill={randomPaletteColor || "#d9d9d9"} />
        <path d="M400 640V560L320 640H400Z" fill={randomPaletteColor || "#d9d9d9"} />
        <path d="M400 720V640L320 720H400Z" fill={randomPaletteColor || "#d9d9d9"} />
        <path d="M400 800V720L320 800H400Z" fill={randomPaletteColor || "#d9d9d9"} />
        <path d="M400 880V800L320 880H400Z" fill={randomPaletteColor || "#d9d9d9"} />
        <path d="M480 80V0L400 80H480Z" fill={randomPaletteColor || "#d9d9d9"} />
        <path d="M480 160V80L400 160H480Z" fill={randomPaletteColor || "#d9d9d9"} />
        <path d="M480 240V160L400 240H480Z" fill={randomPaletteColor || "#d9d9d9"} />
        <path d="M480 320V240L400 320H480Z" fill={randomPaletteColor || "#d9d9d9"} />
        <path d="M480 400V320L400 400H480Z" fill={randomPaletteColor || "#d9d9d9"} />
        <path d="M480 480V400L400 480H480Z" fill={randomPaletteColor || "#d9d9d9"} />
        <path d="M480 560V480L400 560H480Z" fill={randomPaletteColor || "#d9d9d9"} />
        <path d="M480 640V560L400 640H480Z" fill={randomPaletteColor || "#d9d9d9"} />
        <path d="M480 720V640L400 720H480Z" fill={randomPaletteColor || "#d9d9d9"} />
        <path d="M480 800V720L400 800H480Z" fill={randomPaletteColor || "#d9d9d9"} />
        <path d="M480 880V800L400 880H480Z" fill={randomPaletteColor || "#d9d9d9"} />
        <path d="M560 80V0L480 80H560Z" fill={randomPaletteColor || "#d9d9d9"} />
        <path d="M560 160V80L480 160H560Z" fill={randomPaletteColor || "#d9d9d9"} />
        <path d="M560 240V160L480 240H560Z" fill={randomPaletteColor || "#d9d9d9"} />
        <path d="M560 320V240L480 320H560Z" fill={randomPaletteColor || "#d9d9d9"} />
        <path d="M560 400V320L480 400H560Z" fill={randomPaletteColor || "#d9d9d9"} />
        <path d="M560 480V400L480 480H560Z" fill={randomPaletteColor || "#d9d9d9"} />
        <path d="M560 560V480L480 560H560Z" fill={randomPaletteColor || "#d9d9d9"} />
        <path d="M560 640V560L480 640H560Z" fill={randomPaletteColor || "#d9d9d9"} />
        <path d="M560 720V640L480 720H560Z" fill={randomPaletteColor || "#d9d9d9"} />
        <path d="M560 800V720L480 800H560Z" fill={randomPaletteColor || "#d9d9d9"} />
        <path d="M560 880V800L480 880H560Z" fill={randomPaletteColor || "#d9d9d9"} />
        <path d="M640 80V0L560 80H640Z" fill={randomPaletteColor || "#d9d9d9"} />
        <path d="M640 160V80L560 160H640Z" fill={randomPaletteColor || "#d9d9d9"} />
        <path d="M640 240V160L560 240H640Z" fill={randomPaletteColor || "#d9d9d9"} />
        <path d="M640 320V240L560 320H640Z" fill={randomPaletteColor || "#d9d9d9"} />
        <path d="M640 400V320L560 400H640Z" fill={randomPaletteColor || "#d9d9d9"} />
        <path d="M640 480V400L560 480H640Z" fill={randomPaletteColor || "#d9d9d9"} />
        <path d="M640 560V480L560 560H640Z" fill={randomPaletteColor || "#d9d9d9"} />
        <path d="M640 640V560L560 640H640Z" fill={randomPaletteColor || "#d9d9d9"} />
        <path d="M640 720V640L560 720H640Z" fill={randomPaletteColor || "#d9d9d9"} />
        <path d="M640 800V720L560 800H640Z" fill={randomPaletteColor || "#d9d9d9"} />
        <path d="M640 880V800L560 880H640Z" fill={randomPaletteColor || "#d9d9d9"} />
      </svg>
    </div>
  );
};
