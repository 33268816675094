/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import { RefinementList as AlgoliaRefinementList } from "react-instantsearch-dom";

interface RefinementListProps {
  attribute: string;
  transformItems?: (items: Record<string, unknown>) => void;
  limit?: number;
}

const styles = css`
  .ais-RefinementList {
    &-list {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
    &-item {
      padding: 0;
      position: relative;

      &::before {
        width: 20px;
        height: 20px;
        background: #232323;
        content: "";
        display: block;
        border-radius: 2px;
        position: absolute;
        left: 0;
        pointer-events: none;
      }

      &::after {
        width: 12px;
        height: 12px;
        background: #ff735a;
        content: "";
        display: block;
        border-radius: 1px;
        position: absolute;
        left: 4px;
        top: 4px;
        opacity: 0;
        transition: opacity 0.125s ease-in-out;
        pointer-events: none;
      }

      &--selected {
        &::after {
          opacity: 1;
        }
        .ais-RefinementList {
          &-checkbox {
            background: #343434;
          }
          &-labelText {
            opacity: 1;
          }
        }
      }
    }

    &-label {
      display: flex;
      align-items: center;
    }

    &-checkbox {
      margin: 0;
      appearance: none;
      background: #232323;
      border-radius: 2px;
      width: 22px;
      height: 22px;
      margin-right: 8px;
      opacity: 0;
    }

    &-labelText {
      font-size: 14px;
      font-weight: 500;
      opacity: 0.7;
    }

    &--noRefinement {
      + .no-show {
        display: block;
      }
    }

    &-count {
      opacity: 0.5;
      font-size: 12px;
      font-weight: 600;
      margin-left: auto;
    }
  }

  .no-show {
    display: none;
    background: rgba(255, 255, 255, 0.01);
    padding: 16px;
    text-align: center;
    border-radius: 4px;
    border: 1px solid rgba(255, 255, 255, 0.05);

    strong {
      font-weight: 700;
      margin-bottom: 4px;
      font-size: 18px;
    }

    p {
      font-size: 14px;
      opacity: 0.7;
    }
  }
`;

export const RefinementList = ({
  attribute,
  transformItems,
  limit,
}: RefinementListProps) => (
  <div css={styles}>
    <AlgoliaRefinementList
      attribute={attribute}
      transformItems={transformItems}
      limit={limit}
    />
    <div className="no-show">
      <p>No results</p>
    </div>
  </div>
);
