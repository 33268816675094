/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import { getRandomNumber } from "../utils";

const CONFIGURATIONS = [
  {
    width: 480,
    height: 480,
    viewBox: [-120,-120,480,480]
  },
  {
    width: 1000,
    height: 470,
    viewBox: [-500,100,480,480]
  },
  {
    width: 1000,
    height: 470,
    viewBox: [-100,-180,400,480]
  }
]

export const Circle = ({ palette }) => {
  const styles = css`
    svg {
      display: block;
    }
  `;

  const paletteColor = palette[getRandomNumber(1, 3)];
  const { width, height, viewBox} = CONFIGURATIONS[getRandomNumber(0, CONFIGURATIONS.length - 1)];

  return (
    <div css={styles}>
      <svg
        width={width}
        height={height}
        viewBox={viewBox.join(" ")}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          opacity="0.1"
          cx="282.843"
          cy="282.843"
          r="200"
          transform="rotate(-45 282.843 282.843)"
          fill={paletteColor || "white"}
          fillOpacity="0.47"
        />
        <circle
          opacity="0.2"
          cx="294.157"
          cy="294.156"
          r="184"
          transform="rotate(-45 294.157 294.156)"
          fill={paletteColor || "white"}
          fillOpacity="0.47"
        />
        <circle
          opacity="0.3"
          cx="305.471"
          cy="305.47"
          r="168"
          transform="rotate(-45 305.471 305.47)"
          fill={paletteColor || "white"}
          fillOpacity="0.47"
        />
        <circle
          opacity="0.4"
          cx="316.785"
          cy="316.784"
          r="152"
          transform="rotate(-45 316.785 316.784)"
          fill={paletteColor || "white"}
          fillOpacity="0.47"
        />
        <circle
          opacity="0.5"
          cx="328.097"
          cy="328.098"
          r="136"
          transform="rotate(-45 328.097 328.098)"
          fill={paletteColor || "white"}
          fillOpacity="0.47"
        />
        <circle
          opacity="0.6"
          cx="339.411"
          cy="339.411"
          r="120"
          transform="rotate(-45 339.411 339.411)"
          fill={paletteColor || "white"}
          fillOpacity="0.47"
        />
        <circle
          opacity="0.7"
          cx="350.725"
          cy="350.725"
          r="104"
          transform="rotate(-45 350.725 350.725)"
          fill={paletteColor || "white"}
          fillOpacity="0.47"
        />
        <circle
          opacity="0.8"
          cx="362.039"
          cy="362.039"
          r="88"
          transform="rotate(-45 362.039 362.039)"
          fill={paletteColor || "white"}
          fillOpacity="0.47"
        />
        <circle
          opacity="0.9"
          cx="373.353"
          cy="373.353"
          r="72"
          transform="rotate(-45 373.353 373.353)"
          fill={paletteColor || "white"}
          fillOpacity="0.47"
        />
      </svg>
    </div>
  );
};
