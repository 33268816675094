/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import { getRandomNumber } from "../utils";

const CONFIGURATIONS = [
  {
    width: 360,
    height: 240,
    viewBox: [0,0,360,240]
  },
  {
    width: 200,
    height: 800,
    viewBox: [0,0,1,240]
  },
  {
    width: 800,
    height: 140,
    viewBox: [100,50,20,80]
  }
]

export const FlatBars = ({ palette }) => {
  const styles = css`
    svg {
      display: block;
    }
  `;

  const { width, height, viewBox} = CONFIGURATIONS[getRandomNumber(0, CONFIGURATIONS.length - 1)];
  
  return (
    <div css={styles}>
      <svg
        width={width}
        height={height}
        viewBox={viewBox.join(" ")}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="360" height="120" fill={palette[1]} />
        <rect y="120" width="360" height="80" fill={palette[2]} />
        <rect y="200" width="360" height="40" fill={palette[3]} />
      </svg>
    </div>
  );
};
