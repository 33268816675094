/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import { getRandomNumber } from "../utils";

const CONFIGURATIONS = [
  {
    width: 480,
    height: 480,
    viewBox: [120,120,480,480]
  },
  {
    width: 240,
    height: 300,
    viewBox: [20,140,280,280]
  },
  {
    width: 1800,
    height: 800,
    viewBox: [120,120,480,480]
  },
  {
    width: 800,
    height: 800,
    viewBox: [120,120,480,480]
  }
]

export const Comb = ({ palette }) => {
  const styles = css`
    svg {
      display: block;
    }
  `;
  const randomPaletteColor = palette[getRandomNumber(1, 3)];
  const randomOpacity = getRandomNumber(2,8)/10;

  const { width, height, viewBox} = CONFIGURATIONS[getRandomNumber(0, CONFIGURATIONS.length - 1)];

  return (
    <div css={styles}>
      <svg
        width={width}
        height={height}
        viewBox={viewBox.join(" ")}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        opacity={randomOpacity}
      >
        <path
          d="M79.5 544L148.349 583.75V663.25L79.5 703L10.651 663.25V583.75L79.5 544Z"
          fill={randomPaletteColor || '#D9D9D9'}
        />
        <path
          d="M159.5 408L228.349 447.75V527.25L159.5 567L90.651 527.25V447.75L159.5 408Z"
          fill={randomPaletteColor || '#D9D9D9'}
        />
        <path
          d="M239.5 272L308.349 311.75V391.25L239.5 431L170.651 391.25V311.75L239.5 272Z"
          fill={randomPaletteColor || '#D9D9D9'}
        />
        <path
          d="M319.5 136L388.349 175.75V255.25L319.5 295L250.651 255.25V175.75L319.5 136Z"
          fill={randomPaletteColor || '#D9D9D9'}
        />
        <path
          d="M399.5 0L468.349 39.75V119.25L399.5 159L330.651 119.25V39.75L399.5 0Z"
          fill={randomPaletteColor || '#D9D9D9'}
        />
        <path
          d="M237.5 544L306.349 583.75V663.25L237.5 703L168.651 663.25V583.75L237.5 544Z"
          fill={randomPaletteColor || '#D9D9D9'}
        />
        <path
          d="M317.5 408L386.349 447.75V527.25L317.5 567L248.651 527.25V447.75L317.5 408Z"
          fill={randomPaletteColor || '#D9D9D9'}
        />
        <path
          d="M397.5 272L466.349 311.75V391.25L397.5 431L328.651 391.25V311.75L397.5 272Z"
          fill={randomPaletteColor || '#D9D9D9'}
        />
        <path
          d="M477.5 136L546.349 175.75V255.25L477.5 295L408.651 255.25V175.75L477.5 136Z"
          fill={randomPaletteColor || '#D9D9D9'}
        />
        <path
          d="M557.5 0L626.349 39.75V119.25L557.5 159L488.651 119.25V39.75L557.5 0Z"
          fill={randomPaletteColor || '#D9D9D9'}
        />
        <path
          d="M395.5 544L464.349 583.75V663.25L395.5 703L326.651 663.25V583.75L395.5 544Z"
          fill={randomPaletteColor || '#D9D9D9'}
        />
        <path
          d="M475.5 408L544.349 447.75V527.25L475.5 567L406.651 527.25V447.75L475.5 408Z"
          fill={randomPaletteColor || '#D9D9D9'}
        />
        <path
          d="M555.5 272L624.349 311.75V391.25L555.5 431L486.651 391.25V311.75L555.5 272Z"
          fill={randomPaletteColor || '#D9D9D9'}
        />
        <path
          d="M635.5 136L704.349 175.75V255.25L635.5 295L566.651 255.25V175.75L635.5 136Z"
          fill={randomPaletteColor || '#D9D9D9'}
        />
        <path
          d="M715.5 0L784.349 39.75V119.25L715.5 159L646.651 119.25V39.75L715.5 0Z"
          fill={randomPaletteColor || '#D9D9D9'}
        />
        <path
          d="M553.5 544L622.349 583.75V663.25L553.5 703L484.651 663.25V583.75L553.5 544Z"
          fill={randomPaletteColor || '#D9D9D9'}
        />
        <path
          d="M633.5 408L702.349 447.75V527.25L633.5 567L564.651 527.25V447.75L633.5 408Z"
          fill={randomPaletteColor || '#D9D9D9'}
        />
        <path
          d="M713.5 272L782.349 311.75V391.25L713.5 431L644.651 391.25V311.75L713.5 272Z"
          fill={randomPaletteColor || '#D9D9D9'}
        />
        <path
          d="M793.5 136L862.349 175.75V255.25L793.5 295L724.651 255.25V175.75L793.5 136Z"
          fill={randomPaletteColor || '#D9D9D9'}
        />
        <path
          d="M873.5 0L942.349 39.75V119.25L873.5 159L804.651 119.25V39.75L873.5 0Z"
          fill={randomPaletteColor || '#D9D9D9'}
        />
        <path
          d="M711.5 544L780.349 583.75V663.25L711.5 703L642.651 663.25V583.75L711.5 544Z"
          fill={randomPaletteColor || '#D9D9D9'}
        />
        <path
          d="M791.5 408L860.349 447.75V527.25L791.5 567L722.651 527.25V447.75L791.5 408Z"
          fill={randomPaletteColor || '#D9D9D9'}
        />
        <path
          d="M871.5 272L940.349 311.75V391.25L871.5 431L802.651 391.25V311.75L871.5 272Z"
          fill={randomPaletteColor || '#D9D9D9'}
        />
        <path
          d="M951.5 136L1020.35 175.75V255.25L951.5 295L882.651 255.25V175.75L951.5 136Z"
          fill={randomPaletteColor || '#D9D9D9'}
        />
        <path
          d="M1031.5 0L1100.35 39.75V119.25L1031.5 159L962.651 119.25V39.75L1031.5 0Z"
          fill={randomPaletteColor || '#D9D9D9'}
        />
      </svg>
    </div>
  );
};
