/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import React from "react";
import { Panel as AlgoliaPanel } from "react-instantsearch-dom";

interface PanelProps {
  header: string;
}

const styles = css`
  .ais-Panel {
    &-header {
      margin-bottom: 16px;
      font-size: 12px;
      font-weight: 600;
      text-transform: uppercase;
      letter-spacing: 3px;
      color: #ff735a;
    }
  }
`;

export const Panel: React.FC<PanelProps> = ({ header, children }) => (
  <div css={styles}>
    <AlgoliaPanel header={header}>{children}</AlgoliaPanel>
  </div>
);
