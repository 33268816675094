/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import { getRandomNumber } from "../utils";

const CONFIGURATIONS = [
  {
    width: 480,
    height: 480,
    viewBox: [-120,-180,480,480]
  },
  {
    width: 600,
    height: 600,
    viewBox: [-120,-180,480,480]
  },
  {
    width: 320,
    height: 320,
    viewBox: [-120,-180,480,480]
  }
]

export const Corner = ({ palette }) => {
  const styles = css`
    svg {
      display: block;
    }
  `;
  const randomPaletteColor = palette[getRandomNumber(1, 3)];
  const { width, height, viewBox} = CONFIGURATIONS[getRandomNumber(0, CONFIGURATIONS.length - 1)];
  return (
    <div css={styles}>
      <svg
        width={width}
        height={height}
        viewBox={viewBox.join(" ")}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          y="403.572"
          width="570.737"
          height="570.737"
          transform="rotate(-45 0 403.572)"
          fill={randomPaletteColor}
        />
          
      </svg>
    </div>
  );
};
